<template>
  <el-dialog
      :width="componentName!=='result'?'730px':'80%'"
      append-to-body
      title="随机抽取"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="randomSelect-component"
      :class="componentName!=='result'?'':'wider'"
      :before-close="beforeClose"
  >
    <div class="comtainer" v-loading="loading" element-loading-text="正在获取抽选来源的信息">
      <div class="main">
        <div class="item">
          <div class="label">选任单位</div>
          <div class="data">{{ data.xrdwmc }}</div>
        </div>
        <div class="item">
          <div class="label">年度</div>
          <div class="data">{{ data.nd }}</div>
        </div>
        <div class="item">
          <div class="label">选任标题</div>
          <div class="data">{{ data.jhmc }}</div>
        </div>
        <div class="item">
          <div class="label">计划人数</div>
          <div class="data blue">{{ data.sjcxrs }}</div>
        </div>

        <div class="item">
          <div class="label">计划时间</div>
          <div class="data">{{ data.bmkssj }} 至 {{ data.bmjssj }}</div>
        </div>
        <div class="item">
          <div class="label">候选人总人数</div>
          <div class="data blue">{{ data.hxrzrs }}</div>
        </div>
        <div class="item">
          <div class="label">已抽取人数</div>
          <div
              class="data blue"
          >{{
              progress.status != 'finish' && progress && progress.ycxrs ? parseInt(beforeNum) + progress.ycxrs : data.ycxrs
            }}
          </div>
        </div>
        <div class="item">
          <div class="label">可抽取人数</div>
          <div
              class="data blue"
          >{{ progress.status != 'finish' && progress.ycxrs ? parseInt(beforeTotal) - progress.ycxrs : data.kcxrs }}
          </div>
        </div>
        <div class="item">
          <div class="label">抽选来源</div>
          <el-cascader
              v-model="data.cxly"
              :options="options.cxly"
              :props="{  label:'name',value:'id',emitPath:false,checkStrictly: true}"
              placeholder="请选择抽选来源"
              clearable
              :show-all-levels="true"
              size="small"
              :disabled="disabled"
          >
            <template slot-scope="{  data }">
              <span>{{ data.name }}</span>
            </template>
          </el-cascader>
        </div>
        <div class="item">
          <div class="label">拟抽选人数</div>
          <el-input v-model="data.ncxrs" @input="input" placeholder="请输入拟抽选人数" size="mini"
                    :disabled="disabled"></el-input>
        </div>
      </div>
      <div class="select">
        <component
            :is="componentName"
            @start="start"
            @stop="stop"
            :progress="progress"
            @changeProgress="changeProgress"
            @changeComponent="changeComponent"
            @beforeClose="beforeClose"
            @getStatus="getStatus"
            ref="component"
        ></component>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import pie from '@/components/charts/pie';
import category from '@/components/charts/category';

import ready from './component/ready';
import onProgress from './component/onProgress';
import result from './component/result';

import Appointment from '@/api/choose/appointment';
import Public from '@/api/public';
import numberToString from "@/plugin/numberToString";

export default {
  // 随机抽取
  name: 'checkCode',
  props: {
    tableData: {
      type: Array
    }
  },
  components: {
    pie,
    category,
    ready,
    onProgress,
    result,
  },
  data() {
    return {
      show: false,
      componentName: '',
      data: {
        cxly: '',
        ncxrs: '',
      },
      options: {cxly: []},

      progress: {},
      disabled: false,
      loading: false,
      beforeNum: 0,
      beforeTotal: 0
    };
  },
  methods: {
    input() {
      this.data.ncxrs = this.data.ncxrs.replace(/[^0-9]/g, '');
    },
    beforeClose(done) {
      if (this.progress.progress === 100) {
        Appointment.stop()
      }
      console.log('结束');
      if (done instanceof Function) {
        this.toggle(false);
        this.$emit('changeShow', false);
        done();
      } else {
        this.toggle(false);
        this.$emit('changeShow', false);
      }
    },
    changeComponent(name, items = null) {
      if (name !== 'ready') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.componentName = name;
      if (name === 'result') {
        let data = numberToString(items, 'bmfs', this.dictionary.bmfs.children);
        this.$nextTick(() => {
          this.$refs.component.init(data);
        });
        // Appointment.list({
        //   keyword: '',
        //   page: 1,
        //   size: 9999,
        // }).then((res) => {
        //   console.log('res', res)

        //   console.log('列表数据', data)
        //   this.$nextTick(() => {
        //     this.$refs.component.init(data);
        //   });
        //
        // })

      }
    },
    changeProgress(data) {
      this.progress = data;
      this.data.cxly = data.current.xrdw;
    },
    toggle(show) {
      this.show = show;
    },
    // 开始抽选
    start() {
      // if (!this.data.cxly) {
      //   this.$alert('请选择抽选来源');
      //   return;
      // }
      if (!this.data.ncxrs) {
        this.$alert('请输入拟抽选人数');
        return;
      }
      if (this.data.ncxrs <= 0) {
        this.$alert('请输入正确拟抽选人数');
        return;
      }
      if (this.data.ncxrs > this.data.kcxrs) {
        this.$alert('当前可抽选人数不足');
        return;
      }

      Appointment.start({ncxrs: this.data.ncxrs, cxly: this.data.cxly}).then(() => {
        this.changeComponent('onProgress');
      });
    },
    result() {
      Appointment.randomStatus({cxly: this.data.cxly}).then(() => {
      });
    },
    getStatus() {
      Appointment.randomStatus().then((res) => {
        Object.assign(this.data, res);
        window.localStorage.removeItem('SelectbeforNum')
        window.localStorage.removeItem('Selectkcxrs')
      });
    },
    // 抽选结束
    stop() {
      Promise.all([Appointment.randomStatus({cxly: this.data.cxly}), Appointment.stop()]).then((res) => {
        console.log(res[1], 'finish1')
        Object.assign(this.data, res[0]);
        this.$emit('getList');
        window.localStorage.removeItem('SelectbeforNum')
        window.localStorage.removeItem('Selectkcxrs')
        this.changeComponent('result',res[1].items);
      });
    },
  },
  mounted() {
  },
  created() {
    let {bmfs} = this.$store.getters.dic;
    this.dictionary = {bmfs};
    // 判断是否有进程 在抽选，如果有，或者没有查看结果的话就展示
    Promise.all([Appointment.randomStatus(), Appointment.progress(), Public.components({OrgTree: 0})]).then((res) => {
      if (!window.localStorage.getItem('SelectbeforNum')) {
        window.localStorage.setItem('SelectbeforNum', res[0].ycxrs)
      }

      if (!window.localStorage.getItem('Selectkcxrs')) {
        window.localStorage.setItem('Selectkcxrs', res[0].kcxrs)
      }

      this.beforeNum = window.localStorage.getItem('SelectbeforNum')
      this.beforeTotal = window.localStorage.getItem('Selectkcxrs')

      Object.assign(this.data, res[0]);
      this.data = res[0];
      if (res[1]) {
        this.changeProgress(res[1]);
        console.log(res[1]);
        this.data.ncxrs = res[1].ncxrs;
        this.changeComponent('onProgress');
      } else {
        this.changeComponent('ready');
      }
      this.options.cxly = res[2].OrgTree;
    });
  },
  computed: {},
  watch: {
    'data.cxly': function (n) {
      if (n) {
        this.loading = true;
        let start = Date.now();
        Appointment.randomStatus({cxly: n})
            .then((res) => {
              Object.assign(this.data, res);
              console.log(this.data, res);
            })
            .finally(() => {
              let end = Date.now();
              console.log(end, start);
              if (end - start < 800) {
                setTimeout(() => {
                  this.loading = false;
                }, end - start);
              } else {
                this.loading = false;
              }
            });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}

.el-dialog {
  min-width: 650px;

  .comtainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .main {
      font-weight: bold;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;

      .item {
        width: 50%;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        box-sizing: border-box;

        .label {
          width: 100px;
          text-align: right;
          padding-right: 20px;
        }

        .data {
          flex: 1;
          padding-left: 10px;
        }

        .blue {
          color: #337fae;
        }

        .el-input {
          width: 50%;
        }

        .el-select {
          width: 50%;
        }
      }
    }

    .select {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
}

.wider {
  ::v-deep .el-dialog {
    margin: 5vh auto 0 auto !important;
  }

  ::v-deep .el-dialog__body {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }
}
</style>

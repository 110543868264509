<template>
  <section class="component-onProgress">
    <div class="info">
      <div class="circle">
        <el-progress :percentage="progress.status === 'finish'?100:progress.progress" type="circle"></el-progress>
      </div>
      <div class="main" v-show="progress && progress.current && progress.current.xm">
        <div class="item">
          <div class="label">姓名：</div>
          <div class="data">{{progress && progress.current && progress.current.xm}}</div>
        </div>
        <div class="item">
          <div class="label">性别：</div>
          <div class="data">{{progress && progress.current && progress.current.xb}}</div>
        </div>
        <div class="item">
          <div class="label">身份证号码：</div>
          <div class="data">{{progress && progress.current && progress.current.sfzhm}}</div>
        </div>
        <div class="item">
          <div class="label">常居住地：</div>
          <div class="data">{{progress && progress.current && progress.current.cjzd}}</div>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button type="primary" size="small" v-if="progress && progress.status === 'finish'" @click="stop">查看结果</el-button>
    </div>
  </section>
</template>

<script>
import Appointment from '@/api/choose/appointment';
export default {
  // 进行中
  name: 'onProgress',
  props: {
    progress: {
      type: Object,
    },
  },
  data() {
    return {
      timeout: '',
    };
  },
  methods: {
    stop() {
      console.log(this.progress.items);
      this.$emit('stop', this.progress.items);
    },
    update(isFirst) {
      Appointment.progress().then((res) => {
        console.log('progress');
        console.log(res);
        if (!res && !isFirst) {
          clearInterval(this.timeout);
          return;
        } else {
          // if (res.msg) {
          //   this.$message.error('当前抽选来源没有可抽选人数，请分配或者切换场所');
          //   Appointment.stop().then(() => {
          //     this.$emit('changeComponent', 'ready');
          //   });
          // } else {
            this.$emit('changeProgress', res);
            if (res.status === 'finish') {
              clearInterval(this.timeout);
              this.$emit('getStatus');
              this.$message.closeAll();
              this.$message.success('抽选完成');
              this.timeout = '';
            }
          // }
        }
      });
    },
  },
  mounted() {},
  created() {
    this.update(true);
    this.timeout = setInterval(this.update, 2000);
  },
  computed: {},
  watch: {},
  beforeDestroy() {
    clearInterval(this.timeout);
  },
};
</script>

<style scoped lang="scss">
.component-onProgress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .circle {
      text-align: center;
      flex: 1;
    }
    .main {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-bottom: 50px;
      .item {
        font-weight: bold;
        display: flex;
        padding-top: 20px;
        .data {
          color: #337fae;
        }
      }
    }
  }
}
</style>
